*, :before, :after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position: ;
    --tw-gradient-via-position: ;
    --tw-gradient-to-position: ;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / .5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia: ;
    --tw-contain-size: ;
    --tw-contain-layout: ;
    --tw-contain-paint: ;
    --tw-contain-style: ;
}

::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position: ;
    --tw-gradient-via-position: ;
    --tw-gradient-to-position: ;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / .5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia: ;
    --tw-contain-size: ;
    --tw-contain-layout: ;
    --tw-contain-paint: ;
    --tw-contain-style: ;
}

*, :before, :after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #e5e7eb;
}

:before, :after {
    --tw-content: "";
}

html, :host {
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
    font-feature-settings: normal;
    font-variation-settings: normal;
    -webkit-tap-highlight-color: transparent;
}

body {
    margin: 0;
    line-height: inherit;
}

hr {
    height: 0;
    color: inherit;
    border-top-width: 1px;
}

abbr:where([title]) {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
    font-size: inherit;
    font-weight: inherit;
}

a {
    color: inherit;
    text-decoration: inherit;
}

b, strong {
    font-weight: bolder;
}

code, kbd, samp, pre {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
    font-feature-settings: normal;
    font-variation-settings: normal;
    font-size: 1em;
}

small {
    font-size: 80%;
}

sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sub {
    bottom: -.25em;
}

sup {
    top: -.5em;
}

table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse;
}

button, input, optgroup, select, textarea {
    font-family: inherit;
    font-feature-settings: inherit;
    font-variation-settings: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
}

button, select {
    text-transform: none;
}

button, input:where([type=button]), input:where([type=reset]), input:where([type=submit]) {
    -webkit-appearance: button;
    background-color: transparent;
    background-image: none;
}

:-moz-focusring {
    outline: auto;
}

:-moz-ui-invalid {
    box-shadow: none;
}

progress {
    vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
    height: auto;
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
}

::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}

summary {
    display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
    margin: 0;
}

fieldset {
    margin: 0;
    padding: 0;
}

legend {
    padding: 0;
}

ol, ul, menu {
    list-style: none;
    margin: 0;
    padding: 0;
}

dialog {
    padding: 0;
}

textarea {
    resize: vertical;
}

input::-moz-placeholder, textarea::-moz-placeholder {
    opacity: 1;
    color: #9ca3af;
}

input::placeholder, textarea::placeholder {
    opacity: 1;
    color: #9ca3af;
}

button, [role=button] {
    cursor: pointer;
}

:disabled {
    cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
    display: block;
    vertical-align: middle;
}

img, video {
    /*max-width: 100%;*/
    /*height: auto;*/
}

[hidden]:where(:not([hidden=until-found])) {
    display: none;
}

.container {
    width: 100%;
}

@media (min-width: 640px) {
    .container {
        max-width: 640px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 768px;
    }
}

@media (min-width: 1024px) {
    .container {
        max-width: 1024px;
    }
}

@media (min-width: 1280px) {
    .container {
        max-width: 1280px;
    }
}

@media (min-width: 1536px) {
    .container {
        max-width: 1536px;
    }
}

.fixed {
    position: fixed;
}

.absolute {
    position: absolute;
}

.relative {
    position: relative;
}

.left-4 {
    left: 1rem;
}

.mx-2 {
    margin-left: .5rem;
    margin-right: .5rem;
}

.mb-4 {
    margin-bottom: 1rem;
}

.mb-8 {
    margin-bottom: 2rem;
}

.ml-4 {
    margin-left: 1rem;
}

.mr-2 {
    margin-right: .5rem;
}

.mr-3 {
    margin-right: .75rem;
}

.mt-0 {
    margin-top: 0;
}

.mt-1 {
    margin-top: .25rem;
}

.mt-2 {
    margin-top: .5rem;
}

.mt-3 {
    margin-top: .75rem;
}

.mt-3\.5 {
    margin-top: .875rem;
}

.mt-4 {
    margin-top: 1rem;
}

.mt-6 {
    margin-top: 1.5rem;
}

.mt-8 {
    margin-top: 2rem;
}

.block {
    display: block;
}

.inline-block {
    display: inline-block;
}

.flex {
    display: flex;
}

.inline-flex {
    display: inline-flex;
}

.grid {
    display: grid;
}

.hidden {
    display: none;
}

.h-12 {
    height: 3rem;
}

.h-4 {
    height: 1rem;
}

.h-5 {
    height: 1.25rem;
}

.h-8 {
    height: 2rem;
}

.h-\[40px\] {
    height: 40px;
}

.h-\[60px\] {
    height: 60px;
}

.w-1\/3 {
    width: 33.333333%;
}

.w-4 {
    width: 1rem;
}

.w-5 {
    width: 1.25rem;
}

.w-8 {
    width: 2rem;
}

.w-\[180px\] {
    width: 180px;
}

.w-full {
    width: 100%;
}

.min-w-0 {
    min-width: 0;
}

.flex-1 {
    flex: 1 1 0;
}

.shrink-0 {
    flex-shrink: 0;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.animate-spin {
    animation: spin 1s linear infinite;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.grid-cols-1 {
    grid-template-columns:repeat(1, minmax(0, 1fr));
}

.flex-col {
    flex-direction: column;
}

.flex-wrap {
    flex-wrap: wrap;
}

.items-start {
    align-items: flex-start;
}

.items-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.gap-2 {
    gap: .5rem;
}

.gap-6 {
    gap: 1.5rem;
}

.gap-x-1 {
    -moz-column-gap: .25rem;
    column-gap: .25rem;
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.25rem * var(--tw-space-x-reverse));
    margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.5rem * var(--tw-space-x-reverse));
    margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.whitespace-nowrap {
    white-space: nowrap;
}

.rounded {
    border-radius: .25rem;
}

.rounded-3xl {
    border-radius: 1.5rem;
}

.rounded-\[30px\] {
    border-radius: 30px;
}

.rounded-full {
    border-radius: 9999px;
}

.rounded-lg {
    border-radius: .5rem;
}

.border {
    border-width: 1px;
}

.border-2 {
    border-width: 2px;
}

.border-r {
    border-right-width: 1px;
}

.border-t {
    border-top-width: 1px;
}

.border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
}

.border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
}

.border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgb(187 247 208 / var(--tw-border-opacity, 1));
}

.border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgb(252 165 165 / var(--tw-border-opacity, 1));
}

.border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgb(248 113 113 / var(--tw-border-opacity, 1));
}

.border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgb(239 68 68 / var(--tw-border-opacity, 1));
}

.bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(240 253 244 / var(--tw-bg-opacity, 1));
}

.bg-neutral-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(245 245 245 / var(--tw-bg-opacity, 1));
}

.bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 226 226 / var(--tw-bg-opacity, 1));
}

.bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 242 242 / var(--tw-bg-opacity, 1));
}

.bg-transparent {
    background-color: transparent;
}

.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.from-white {
    --tw-gradient-from: #fff var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.via-indigo-50 {
    --tw-gradient-to: rgb(238 242 255 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.to-indigo-100 {
    --tw-gradient-to: #e0e7ff var(--tw-gradient-to-position);
}

.p-10 {
    padding: 2.5rem;
}

.p-4 {
    padding: 1rem;
}

.px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
}

.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.py-0 {
    padding-top: 0;
    padding-bottom: 0;
}

.py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
}

.py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
}

.py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.pr-2 {
    padding-right: .5rem;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
}

.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}

.text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
}

.text-xs {
    font-size: .75rem;
    line-height: 1rem;
}

.font-bold {
    font-weight: 700;
}

.font-medium {
    font-weight: 500;
}

.font-normal {
    font-weight: 400;
}

.font-semibold {
    font-weight: 600;
}

.leading-\[1\.2\] {
    line-height: 1.2;
}

.tracking-\[-0\.02em\] {
    letter-spacing: -.02em;
}

.tracking-wide {
    letter-spacing: .025em;
}

.text-black {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.text-blue-600 {
    --tw-text-opacity: 1;
    color: rgb(37 99 235 / var(--tw-text-opacity, 1));
}

.text-gray-400 {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity, 1));
}

.text-gray-500 {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

.text-gray-700 {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}

.text-gray-900 {
    --tw-text-opacity: 1;
    color: rgb(17 24 39 / var(--tw-text-opacity, 1));
}

.text-green-800 {
    --tw-text-opacity: 1;
    color: rgb(22 101 52 / var(--tw-text-opacity, 1));
}

.text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgb(79 70 229 / var(--tw-text-opacity, 1));
}

.text-pink-600 {
    --tw-text-opacity: 1;
    color: rgb(219 39 119 / var(--tw-text-opacity, 1));
}

.text-red-500 {
    --tw-text-opacity: 1;
    color: rgb(239 68 68 / var(--tw-text-opacity, 1));
}

.text-red-600 {
    --tw-text-opacity: 1;
    color: rgb(220 38 38 / var(--tw-text-opacity, 1));
}

.text-red-700 {
    --tw-text-opacity: 1;
    color: rgb(185 28 28 / var(--tw-text-opacity, 1));
}

.text-red-800 {
    --tw-text-opacity: 1;
    color: rgb(153 27 27 / var(--tw-text-opacity, 1));
}

.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgb(250 204 21 / var(--tw-text-opacity, 1));
}

.underline {
    text-decoration-line: underline;
}

.opacity-25 {
    opacity: .25;
}

.opacity-50 {
    opacity: .5;
}

.opacity-75 {
    opacity: .75;
}

.shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / .05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / .1), 0 8px 10px -6px rgb(0 0 0 / .1);
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.ring-red-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 202 202 / var(--tw-ring-opacity, 1));
}

.blur {
    --tw-blur: blur(8px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-filter {
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s;
}

.duration-300 {
    transition-duration: .3s;
}

.container {
    max-width: 1318px;
    margin: 0 auto;
    padding: 30px;
}

.primary-btn {
    --tw-bg-opacity: 1;
    background-color: rgb(79 70 229 / var(--tw-bg-opacity, 1));
    padding: .5rem 1.5rem;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity, 1));
    border-radius: 30px;
}

.before\:absolute:before {
    content: var(--tw-content);
    position: absolute;
}

.before\:left-0:before {
    content: var(--tw-content);
    left: 0;
}

.before\:top-1\/2:before {
    content: var(--tw-content);
    top: 50%;
}

.before\:h-\[1px\]:before {
    content: var(--tw-content);
    height: 1px;
}

.before\:w-full:before {
    content: var(--tw-content);
    width: 100%;
}

.before\:bg-gray-200:before {
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
}

.invalid\:border-red-500:invalid {
    --tw-border-opacity: 1;
    border-color: rgb(239 68 68 / var(--tw-border-opacity, 1));
}

.invalid\:ring-red-200:invalid {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 202 202 / var(--tw-ring-opacity, 1));
}

.hover\:border-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(96 165 250 / var(--tw-border-opacity, 1));
}

.hover\:text-green-900:hover {
    --tw-text-opacity: 1;
    color: rgb(20 83 45 / var(--tw-text-opacity, 1));
}

.hover\:text-indigo-600:hover {
    --tw-text-opacity: 1;
    color: rgb(79 70 229 / var(--tw-text-opacity, 1));
}

.hover\:text-red-900:hover {
    --tw-text-opacity: 1;
    color: rgb(127 29 29 / var(--tw-text-opacity, 1));
}

.hover\:underline:hover {
    text-decoration-line: underline;
}

.focus\:border-indigo-500:focus {
    --tw-border-opacity: 1;
    border-color: rgb(99 102 241 / var(--tw-border-opacity, 1));
}

.focus\:bg-blue-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgb(239 246 255 / var(--tw-bg-opacity, 1));
}

.focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.focus\:ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-indigo-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(199 210 254 / var(--tw-ring-opacity, 1));
}

.active\:bg-blue-100:active {
    --tw-bg-opacity: 1;
    background-color: rgb(219 234 254 / var(--tw-bg-opacity, 1));
}

.group:hover .group-hover\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgb(37 99 235 / var(--tw-text-opacity, 1));
}

@media (min-width: 640px) {
    .sm\:mt-4 {
        margin-top: 1rem;
    }

    .sm\:block {
        display: block;
    }

    .sm\:inline {
        display: inline;
    }

    .sm\:flex {
        display: flex;
    }

    .sm\:w-\[400px\] {
        width: 400px;
    }

    .sm\:justify-start {
        justify-content: flex-start;
    }

    .sm\:gap-10 {
        gap: 2.5rem;
    }

    .sm\:px-4 {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .sm\:px-6 {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .sm\:py-6 {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }

    .sm\:pr-4 {
        padding-right: 1rem;
    }

    .sm\:text-left {
        text-align: left;
    }

    .sm\:text-8xl {
        font-size: 6rem;
        line-height: 1;
    }

    .sm\:text-base {
        font-size: 1rem;
        line-height: 1.5rem;
    }

    .sm\:text-sm {
        font-size: .875rem;
        line-height: 1.25rem;
    }
}

@media (min-width: 768px) {
    .md\:mt-0 {
        margin-top: 0;
    }

    .md\:inline {
        display: inline;
    }

    .md\:flex-row {
        flex-direction: row;
    }

    .md\:items-end {
        align-items: flex-end;
    }

    .md\:justify-start {
        justify-content: flex-start;
    }

    .md\:text-left {
        text-align: left;
    }
}

@media (min-width: 1024px) {
    .lg\:mt-10 {
        margin-top: 2.5rem;
    }

    .lg\:w-\[500px\] {
        width: 500px;
    }

    .lg\:grid-cols-2 {
        grid-template-columns:repeat(2, minmax(0, 1fr));
    }

    .lg\:pb-16 {
        padding-bottom: 4rem;
    }
}

@media (prefers-color-scheme: dark) {
    .dark\:text-white {
        --tw-text-opacity: 1;
        color: rgb(255 255 255 / var(--tw-text-opacity, 1));
    }
}
